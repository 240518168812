import { JSONpreparrayforurl } from "@/lib/utils/JSONpreparrayforurl";
import ChatLikesButton from "./chat-likes-button";
import { handlers } from "@/lib/utils/handlers/chat";
import CloseButton from "./CloseButton";

export default function ChatModalHeader({
  title,
  chat,
  userdata,
  session,
  profiledata,
  ssr_data_tribe,
  openModal,
  closeMenu,
  closeModal,
}) {
  return (
    <div className="flex items-center content-center mb-4 ">
      <div className="flex-1">{title}</div>
      <div className="flex-0 mr-2">
        <>
          <ChatLikesButton
            likes_json={JSONpreparrayforurl(chat?.likes_json)}
            chatter_id={chat?.id}
            my_id={userdata?.id}
            onClick={() => {
              if (session) {
                handlers(ssr_data_tribe).likeHandler(
                  chat?.chat_id,
                  chat?.id,
                  userdata?.id,
                  profiledata?.id
                );
              }
            }}
            likes={chat?.likes}
            chat_emoji={profiledata.chat_emoji}
            openModal={openModal}
            session={session}
            iconSizeClass={`text-lg px-1 py-0.5`}
            closeMenu={closeMenu}
            show_number={true}
            chat={chat}
          />
        </>
      </div>
      <div className="flex-0 ">
        <CloseButton closeChatModal={closeModal} />
      </div>
    </div>
  );
}

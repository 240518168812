export const scrollToElement = (
  id,
  behavior = "smooth" as ScrollBehavior,
  block = "start" as ScrollLogicalPosition,
  inline = "start" as ScrollLogicalPosition
) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: behavior,
      block: block,
      inline: inline,
    });
  }
};

import { getUserListenExperience } from "./getStreamUrl";
import { getTimestamp } from "./getTimestamp";
import { hasData } from "./hasData";
import { validJson } from "./isJsonValid";

export const startPlayerTimestamp = async (
  timestamp,
  player_state,
  set_player_state,
  userdata,
  itemdata
) => {
  await set_player_state({});

  let duration = hasData(itemdata?.post_contents_json)
    ? validJson(itemdata?.post_contents_json, [])[0]?.duration
    : 0;

  let player_object = {
    ...player_state,
    file: `${validJson(itemdata?.post_contents_json, [])[0]?.url}`,
    start_seek: getTimestamp(timestamp) !== 0 ? timestamp : 0,
    seek: getTimestamp(timestamp) !== 0 ? timestamp : 0,
    seek_ratio:
      getTimestamp(timestamp) !== 0 ? (timestamp * 100) / duration : 0,
    seek_slider:
      getTimestamp(timestamp) !== 0 ? (timestamp * 100) / duration : 0,
    post_id: itemdata?.post_id,
    content_id: itemdata?.content_id,
    content_table: itemdata?.content_table,
    user_id: itemdata?.user_id,
    user_name: itemdata?.user_name,
    player_type: "file",
    title: itemdata?.post_title,
    duration: duration,
    stream_start_date: itemdata?.crdate,
    start_date: itemdata?.crdate,
    isPlaying: true,
    isMuted: false,
    isEnded: false,
    avatar_url: itemdata?.avatar_url,
    isHide: "0",
    listen_experience: getUserListenExperience(userdata),
  };

  await set_player_state(player_object);
};

import SvgJsx from "../templateux/svg/svg-jsx";

export default function CloseButton({ closeChatModal }) {
  return (
    <button
      type="button"
      className="inline-flex justify-center px-2 py-1 text-xs sm:text-md font-medium hover:text-white text-gray-700  border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
      onClick={() => closeChatModal()}
    >
      <SvgJsx
        type={`fill`}
        icon={`x-sm`}
        className={`w-5 h-5 flex-0 my-auto `}
        title={`Credit message`}
      />
    </button>
  );
}

import { hasData } from "./hasData";

export const growText = (likes) => {
  if (!hasData(likes)) return " "; //0
  if (likes > 7) return " text-[30px] font-[1000] ";
  return (
    {
      "0": "  ",
      "1": " text-[14px] ",
      "2": " text-[17px] ",
      "3": " text-[20px] ",
      "4": " text-[23px] ",
      "5": " text-[26px] ",
      "6": "  text-[30px] ",
      "7": "  text-[30px] font-[1000] ",
    }[likes?.toString()] || " "
  );
};

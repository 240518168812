import { hideDelete } from "@/lib/utils/hideDelete";
import { idInJson } from "@/lib/utils/idInJson";
import { isBlocked } from "@/lib/utils/isBlocked";
import React, { useEffect, useRef, useState } from "react";
import { ChatMessageOptions } from "./chat-message-options";
import AuthorCredit from "../templateux/user/author-credit";
import { JSONpreparrayforurl } from "@/lib/utils/JSONpreparrayforurl";
import SvgJsx from "../templateux/svg/svg-jsx";
import { handlers } from "../../lib/utils/handlers/chat";
import { chatauthor } from "./chatauthor";
import { hasData } from "@/lib/utils/hasData";
import ChatLikesButtonUnder from "./chat-likes-button-under";
import { useConfirm } from "@/lib/utils/hooks/useConfirm";
import { config } from "@/lib/config";
import { textProcess } from "@/lib/utils/textprocess";
import ChatContent from "./ChatContent";
import { ChatMessageButtons } from "./ChatMessageButtons";
import { startPlayerTimestamp } from "@/lib/utils/startPlayerTimestamp";
import { timerFormatSeconds } from "@/lib/utils/timerFormat";
import { validJson } from "@/lib/utils/isJsonValid";
import AvatarFlairSizes from "../templateux/user/avatar-flair-sizes";
import { growText } from "@/lib/utils/growText";

const MessageNested = ({
  index,
  type,
  chat,
  profiledata,
  ssr_data_tribe,
  userdata,
  show_header,
  show_footer,
  ssr_isadmin,
  helptype,
  openModal,
  openModalChooser,
  closeModal,
  msg,
  set_msg,
  session,
  chat_emoji,
  inputRef,
  position,
  time,
  chatWidth,
  set_commenting,
  server_website,
  display_actions = true,
  params,
  origin,
  router,
  player_state,
  set_player_state,
  itemdata,
  highlight,
  set_highlight,
  filterRef = null,
}) => {
  useEffect(() => {
    if (
      chat?.chat_id?.toString() !== "0" &&
      (params?.cursor?.toString() == chat?.chat_id?.toString() ||
        msg.target_chat_id == chat?.chat_id?.toString())
    ) {
      set_highlight(chat?.chat_id);
    }
  }, [params?.cursor, msg?.target_chat_id, chat?.chat_id]);

  const menuRef = useRef(null);
  const { isConfirmed } = useConfirm();
  const closeMenu = () => {
    if (menuRef.current) menuRef.current.close();
  };

  // useEffect(()=>{console.log(chat)},[chat])

  const [image, set_image] = useState(
    hasData(chat?.image_json) ? validJson(chat?.image_json) : null
  );

  useEffect(() => {
    if (hasData(chat?.image_json)) {
      set_image(validJson(chat?.image_json));
    }
  }, [chat]);

  var message_content =
    userdata?.bol_ignore_filters?.toString() == "1"
      ? chat?.message_original
      : chat?.message;

  return (
    <div
      className={`${
        highlight?.toString() == chat?.chat_id?.toString() &&
        chat?.chat_id?.toString() !== "0"
          ? "border-y border-l-4 border-red-500 "
          : chat?.bol_private?.toString() == "1"
          ? "border-l-4 border-[#330505]"
          : "border-l-4 border-transparent"
      }`}
    >
      <div
        className={`flex items-top content-center px-2 group ${
          show_header && index > 0 ? " border-t  border-gray-700 " : ""
        }
           ${
             chat?.bol_private?.toString() == "1"
               ? `bg-[#330505] text-white border-[#330505]
               
              hover:bg-[rgb(57,11,11)]
               `
               : `
               bg-gray-800 text-white border-gray-700
                hover:bg-[#273345]
               `
           } 

           ${show_header ? "pt-3" : ""}

            ${show_footer ? "pb-3" : ""}
           
           `}
      >
        {/* DESKTOP AVATAR COLUMN */}
        {show_header && (
          <div
            className={`flex-0 hidden xs:block
             `}
          >
            <AvatarFlairSizes
              isPlaying={chat?.stream_status == "1"}
              isContributor={chat?.contributor_level > 0}
              isBot={["server", "bot"].indexOf(chat?.type?.toString()) > -1}
              avatar_url={chatauthor(
                chat,
                `avatar_url`,
                profiledata?.bot_default_name,
                profiledata?.bot_default_image
              )}
              avatar_url_sfw={chatauthor(
                chat,
                `avatar_url_sfw`,
                profiledata?.bot_default_name,
                profiledata?.bot_default_image
              )}
              bol_nsfw={chatauthor(
                chat,
                `bol_nsfw`,
                profiledata?.bot_default_name,
                profiledata?.bot_default_image
              )}
              avatar_crdate={chatauthor(
                chat,
                `avatar_crdate`,
                profiledata?.bot_default_name,
                profiledata?.bot_default_image
              )}
              id={chatauthor(
                chat,
                `id`,
                profiledata?.bot_default_name,
                profiledata?.bot_default_image
              )}
              user_name={chatauthor(
                chat,
                `user_name`,
                profiledata?.bot_default_name,
                profiledata?.bot_default_image
              )}
              size={"md"}
            />
          </div>
        )}

        {/* CHAT CONTENT */}
        <div
          className="flex-1
             
             
             "
        >
          {/* HEADER CONTENT - ONLY SHOW WHEN NEW */}
          {show_header && (
            <div className="flex items-center content-center xs:ml-[10px]">
              {/* DESKTOP (!Avatar) */}
              <div className={`flex-0 hidden xs:block cursor-pointer`}>
                <div
                  onClick={
                    origin == "chat"
                      ? () => {
                          ["message", "bot", "server"].indexOf(type) > -1;
                          openModal(
                            `["${chat?.id}"]`,
                            "users",
                            "chat",
                            1,
                            chat
                          );
                        }
                      : () => {
                          router.push(
                            `/${profiledata?.user_name?.toLowerCase()}/chat/${
                              chat?.chat_id
                            }`
                          );
                        }
                  }
                >
                  <AuthorCredit
                    display_data={chat}
                    isPlaying={false}
                    profiledata={profiledata}
                    show_avatar={false}
                    custom_flair={chatauthor(
                      chat,
                      `custom_flair`,
                      profiledata?.bot_default_name,
                      profiledata?.bot_default_image
                    )}
                    flair_t={chatauthor(
                      chat,
                      `flair_t`,
                      profiledata?.bot_default_name,
                      profiledata?.bot_default_image
                    )}
                    flair_b={chatauthor(
                      chat,
                      `flair_b`,
                      profiledata?.bot_default_name,
                      profiledata?.bot_default_image
                    )}
                    flair_c={chatauthor(
                      chat,
                      `flair_c`,
                      profiledata?.bot_default_name,
                      profiledata?.bot_default_image
                    )}
                    bol_mod={chatauthor(
                      chat,
                      `bol_mod`,
                      profiledata?.bot_default_name,
                      profiledata?.bot_default_image
                    )}
                    size={"none"}
                  />
                </div>
              </div>

              {/* MOBILE CREDITS (w Avatar) */}
              <div className={`flex-0 block xs:hidden cursor-pointer`}>
                <div
                  onClick={
                    origin == "chat"
                      ? () => {
                          ["message", "bot", "server"].indexOf(type) > -1;
                          openModal(
                            `["${chat?.id}"]`,
                            "users",
                            "chat",
                            1,
                            chat
                          );
                        }
                      : () => {
                          router.push(
                            `/${profiledata?.user_name?.toLowerCase()}/chat/${
                              chat?.chat_id
                            }`
                          );
                        }
                  }
                >
                  <AuthorCredit
                    display_data={chat}
                    isPlaying={false}
                    profiledata={profiledata}
                    show_avatar={true}
                    custom_flair={chatauthor(
                      chat,
                      `custom_flair`,
                      profiledata?.bot_default_name,
                      profiledata?.bot_default_image
                    )}
                    flair_t={chatauthor(
                      chat,
                      `flair_t`,
                      profiledata?.bot_default_name,
                      profiledata?.bot_default_image
                    )}
                    flair_b={chatauthor(
                      chat,
                      `flair_b`,
                      profiledata?.bot_default_name,
                      profiledata?.bot_default_image
                    )}
                    flair_c={chatauthor(
                      chat,
                      `flair_c`,
                      profiledata?.bot_default_name,
                      profiledata?.bot_default_image
                    )}
                    bol_mod={chatauthor(
                      chat,
                      `bol_mod`,
                      profiledata?.bot_default_name,
                      profiledata?.bot_default_image
                    )}
                    size={"micro"}
                  />
                </div>
              </div>

              <div className="flex-1 "></div>

              {/* ACTIONS */}
              <div className="flex-0">
                <div className="flex items-center content-center">
                  {/* PRIVATE */}
                  {type == "message" && chat?.bol_private == 1 && (
                    <div
                      className={`cursor-pointer p-0.5 px-1.5 flex items-center content-center flex-0 ml-2  rounded-full 
                        bg-red-600  text-white
                        `}
                    >
                      <div className="flex-0">
                        <SvgJsx
                          type={`fill`}
                          icon={`lock-closed-sm`}
                          className={` w-3 h-3 flex-0 my-auto`}
                          title={`Private message`}
                        />
                      </div>
                      <div className="flex-1 text-3xs hidden sm:block font-bold ml-1">
                        {textProcess(`PRIVATE`)}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {origin == "chat" && (
                <div
                  className="flex-0 ml-2 min-w-[24px] text-right text-xs"
                  title={new Date(chat?.crdate)?.toString()}
                >
                  {new Date(chat?.crdate).toLocaleTimeString("en-us", {
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </div>
              )}
              {/* TIMESTAMPS FOR BROADCASTS */}
              {["replay", "post"]?.indexOf(origin) > -1 && (
                <div className="flex-0 ml-2 min-w-[24px] text-right -mt-4 ">
                  <button
                    className={` pr-2 text-blue-400 underline hover:no-underline cursor-pointer  pt-3`}
                    onClick={() => {
                      startPlayerTimestamp(
                        chat.broadcast_duration,
                        player_state,
                        set_player_state,
                        userdata,
                        itemdata
                      );
                    }}
                  >
                    {timerFormatSeconds(Math.round(chat.broadcast_duration))}
                  </button>
                </div>
              )}
            </div>
          )}

          {/* MESSAGE CONTENT - ALWAYS SHOW */}
          <div className="flex items-top content-top ">
            <div
              className={`flex-1 ml-0
                ${
                  !show_header &&
                  chatWidth >= config.chat.windowoffset.minmessagewidth
                    ? " xs:ml-[42px]" //non-header messages
                    : "xs:ml-[10px]" //header-accompanying messages
                }
                 ${
                   {
                     "1": "text-gray-200",
                     "0": "text-white",
                     "-1": "text-red-600",
                   }[type == "message" ? chat?.placeholder?.toString() : "0"]
                 }
                 markdown  
                 `}
            >
              <div
                className=" group relative break-words"
                style={{ maxWidth: chatWidth }}
              >
                <div
                  className={` 
                    
                    ${growText(chat?.likes_shadow)}
                    
                    ${
                      chat?.bol_filtered?.toString() == "1" &&
                      userdata?.bol_ignore_filters?.toString() == "0"
                        ? " underline decoration-dotted decoration-gray-500 "
                        : ""
                    }
                    `}
                >
                  {hideDelete(
                    <ChatContent
                      chat={chat}
                      profiledata={profiledata}
                      ssr_data_tribe={ssr_data_tribe}
                      userdata={userdata}
                      server_website={server_website}
                      image={image}
                      set_image={set_image}
                      max_width={filterRef?.current == "ai" ? 800 : 400}
                      max_height={filterRef?.current == "ai" ? 600 : 200}
                      openModalChooser={openModalChooser}
                      session={session}
                      openModal={openModal}
                      closeMenu={closeMenu}
                      closeModal={closeModal}
                      router={router}
                      highlight={highlight}
                      set_highlight={set_highlight}
                    />,
                    chat?.bol_delete,
                    chat?.user_status
                  )}
                </div>
                <div
                  onClick={
                    origin == "chat"
                      ? () => {
                          ["message", "bot", "server"].indexOf(type) > -1;
                          openModal(
                            `["${chat?.id}"]`,
                            "users",
                            "chat",
                            1,
                            chat
                          );
                        }
                      : () => {
                          router.push(
                            `/${profiledata?.user_name?.toLowerCase()}/chat/${
                              chat?.chat_id
                            }`
                          );
                        }
                  }
                  className={` hidden 

                                        ${
                                          chatWidth <
                                          config.chat.windowoffset
                                            .minmessagewidth
                                            ? ""
                                            : "group-hover:xs:flex"
                                        }
                           
                                         items-center content-center absolute  
                                        top-[1px] 
                                        flex-0 
                                        text-gray-500 hover:underline cursor-pointer
                                        -left-[40px]
                                        text-3xs
                                        `}
                  title={new Date(chat?.crdate).toLocaleTimeString("en-us", {
                    weekday: "short",
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })}
                >
                  {new Date(chat?.crdate).toLocaleTimeString("en-us", {
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </div>

                {session && display_actions && (
                  <div
                    className={` hidden group-hover:flex items-center content-center absolute  
                                        -top-[24px] 
                                        flex-0 
                                        bg-gray-700
                                        -right-[4px]
                                          rounded-md
                                          overflow-hidden
                                         shadow-md
                                        `}
                  >
                    {/* ACTIONS */}
                    <ChatMessageButtons
                      chat={chat}
                      profiledata={profiledata}
                      ssr_data_tribe={ssr_data_tribe}
                      msg={msg}
                      set_msg={set_msg}
                      inputRef={inputRef}
                      set_commenting={set_commenting}
                      ssr_isadmin={ssr_isadmin}
                      helptype={helptype}
                      userdata={userdata}
                      isConfirmed={isConfirmed}
                      openModal={openModal}
                      message_content={message_content}
                      session={session}
                      sizeClass={`w-4 h-4`}
                      chatoptions={
                        <div className="flex-0 text-gray-700">
                          <ChatMessageOptions
                            chatter_id={chat?.id}
                            chat_id={chat?.chat_id}
                            chat_text={hideDelete(
                              chat?.message_original,
                              chat?.bol_delete,
                              chat?.user_status
                            )}
                            chatter_name={chat?.user_name}
                            clicker_name={userdata?.user_name}
                            clicker_id={userdata?.id}
                            owner_id={profiledata?.id}
                            owner_name={profiledata?.user_name}
                            shrink={true}
                            handlers={handlers}
                            handleReportAdmin={() => {}}
                            ssr_isadmin={ssr_isadmin}
                            helptype={helptype}
                            isOwner={chat?.id == userdata?.id}
                            isIgnored={idInJson(
                              userdata?.ignore_json,
                              chat?.id
                            )}
                            isApproved={idInJson(
                              profiledata?.approve_json,
                              chat?.id
                            )}
                            isBlocked={isBlocked(
                              profiledata?.block_json,
                              chat?.timestamp,
                              chat?.user_name
                            )}
                            openModal={openModal}
                            likes={chat?.likes}
                            likes_json={chat?.likes_json}
                            saves={chat?.saves}
                            saves_json={chat?.saves_json}
                            reports={chat?.reports}
                            reports_json={chat?.reports_json}
                            crdate={chat?.crdate}
                            message={
                              <>
                                {hideDelete(
                                  <ChatContent
                                    chat={chat}
                                    profiledata={profiledata}
                                    ssr_data_tribe={ssr_data_tribe}
                                    userdata={userdata}
                                    server_website={server_website}
                                    image={image}
                                    set_image={set_image}
                                    max_width={200}
                                    max_height={90}
                                    openModalChooser={openModalChooser}
                                    session={session}
                                    openModal={openModal}
                                    closeMenu={closeMenu}
                                    closeModal={closeModal}
                                    router={router}
                                    highlight={highlight}
                                    set_highlight={set_highlight}
                                  />,
                                  chat?.bol_delete,
                                  chat?.user_status
                                )}
                              </>
                            }
                            message_original_raw={hideDelete(
                              chat?.message_original_raw,
                              chat?.bol_delete,
                              chat?.user_status
                            )}
                            emoji={chat_emoji}
                            bol_filtered={chat?.bol_filtered}
                            position={position}
                            type={chat?.type}
                            session={session}
                            chat={chat}
                            ssr_data_tribe={ssr_data_tribe}
                          />
                        </div>
                      }
                    />
                  </div>
                )}
              </div>
              <div className="flex items-center content-center">
                <div
                  className={`
                            ${
                              parseInt(chat?.likes) > 0 && display_actions
                                ? "block"
                                : "hidden"
                            } 
                            flex-0 mt-1 mr-2

                            `}
                >
                  <ChatLikesButtonUnder
                    likes_json={JSONpreparrayforurl(chat?.likes_json)}
                    chatter_id={chat?.id}
                    my_id={userdata?.id}
                    onClick={() => {
                      if (session) {
                        handlers(ssr_data_tribe)?.likeHandler(
                          chat?.chat_id,
                          chat?.id,
                          userdata?.id,
                          profiledata?.id
                        );
                      }
                    }}
                    likes={chat?.likes}
                    chat_emoji={chat_emoji}
                    openModal={openModal}
                    session={session}
                    closeMenu={closeMenu}
                    show_number={true}
                    chat={chat}
                  />
                </div>
                {
                  <div
                    className={`
                            ${
                              chat?.bol_kudos?.toString() == "1"
                                ? "flex"
                                : "hidden"
                            } 
                            flex-0 mt-1  text-yellow-500

                            `}
                  >
                    <SvgJsx
                      type={`fill`}
                      icon={`light-bulb-sm`}
                      className={` w-4 h-4 flex-0 my-auto `}
                      title={`Credit message`}
                    />
                    <div className="text-xs font-bold">
                      kudos awarded by host!
                    </div>
                  </div>
                }
              </div>
            </div>
            {type == "message" && chat?.placeholder == "-1" && (
              <div className="flex-0 flex text-2xs text-white bg-red-500 p-1 rounded-md">
                undelivered!
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Message = MessageNested;

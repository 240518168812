import SvgJsx from "@/components/templateux/svg/svg-jsx";
import { useEffect, useState } from "react";

export default function ChatKudosButton({
  bol_kudos,
  onClick,
  className = `w-4 h-4`,
}) {
  const [did_kudos, set_did_kudos] = useState(bol_kudos);

  useEffect(() => {
    set_did_kudos(bol_kudos);
  }, [bol_kudos]);

  return (
    <>
      <div
        className={`flex-0 pl-1 cursor-pointer rounded-md w-5 mr-1
                    
                    ${
                      did_kudos
                        ? "text-yellow-500 hover:text-text-yellow-400"
                        : " text-gray-400  hover:text-white"
                    }
                                     `}
        onClick={onClick}
      >
        <SvgJsx
          type={`fill`}
          icon={`light-bulb-sm`}
          className={`${className} flex-0 my-auto `}
          title={`Credit message`}
        />
      </div>
    </>
  );
}

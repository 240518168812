import SvgJsx from "@/components/templateux/svg/svg-jsx";
import * as React from "react";

import { Fragment, useRef, useState, memo } from "react";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import PopupMenuOption from "@/components/templateux/popup-menu-option";
import { blockactions } from "@/lib/config";
import AuthContext from "@/components/auth/context";
import { useConfirm } from "@/lib/utils/hooks/useConfirm";
import Link from "next/link";

const ChatMessageOptionsNested = ({
  chatter_id,
  chat_id,
  chat_text,
  chatter_name,
  clicker_name,
  clicker_id,
  owner_id,
  owner_name,
  shrink = false,
  handlers,
  handleReportAdmin,
  ssr_isadmin,
  helptype,
  isOwner,
  isBlocked,
  isIgnored,
  isApproved,
  openModal,
  likes,
  likes_json,
  saves,
  saves_json,
  reports,
  reports_json,
  crdate,
  message,
  message_original_raw,
  emoji,
  bol_filtered,
  position = "left top",
  type = "message",
  session,
  chat,
  ssr_data_tribe,
}) => {
  const menuRef = useRef(null);
  const closeMenu = () => {
    if (menuRef.current) menuRef.current.close();
  };

  const [toggle_raw, set_toggle_raw] = useState(false);

  const { isConfirmed } = useConfirm();

  const vcrdate = new Date(crdate);
  const authContext = React.useContext(AuthContext);

  const did_i_save = saves_json?.includes(`"${clicker_id}"`?.toString());
  const did_i_report = reports > 0;

  return (
    <div className="menu flex items-center content-center  flex-0  ">
      <Popup
        trigger={
          <div aria-haspopup="true" className="menu-item w-5 h-5 flex-0 ">
            <SvgJsx
              type="outline"
              icon="dots-v"
              className={`cursor-pointer  flex-0 mr-1 rounded-md w-5 h-5

                                ${
                                  bol_filtered?.toString() == "1"
                                    ? `
                                text-red-400 hover:text-red-200
                                  `
                                    : `
                                text-gray-400 hover:text-gray-200
                                  `
                                }

                              `}
              title="More"
            />
          </div>
        }
        position={"left center"}
        on="click"
        closeOnDocumentClick
        closeOnEscape
        mouseLeaveDelay={300}
        mouseEnterDelay={0}
        contentStyle={{
          padding: "0px",
          border: "none",
          backgroundColor: "transparent",
          width: "100%",
          maxWidth: "260px",
        }}
        arrow={false}
        nested
        ref={menuRef}
      >
        <>
          <div
            className="menu  flex flex-col items-start content-top gap-y-1 rounded-md p-2
                    bg-gray-800 "
          >
            {/* COL LEFT */}
            <div className="flex-1  w-full">
              <div className="w-full flex-col flex mb-2">
                <div className="flex items-center content-center text-2xs">
                  <div className="flex-1">
                    <div>
                      ID
                      <Link
                        className="underline hover:no-underline ml-1 cursor-pointer"
                        href={`/${owner_name}/chat/${chat_id}`}
                      >
                        {chat_id}
                      </Link>
                    </div>
                    <div
                      className="underline hover:no-underline cursor-pointer"
                      onClick={() => {
                        closeMenu();
                        openModal(chatter_name, "users", "chat", 1, chat);
                      }}
                    >
                      {chatter_name}
                    </div>
                  </div>
                  <div className="flex items-center content-center">
                    <div className="text-2xs flex-1 text-right mr-1">
                      {vcrdate.toLocaleString()}
                    </div>
                    <div
                      onClick={() => set_toggle_raw((prev) => !prev)}
                      className={`cursor-pointer border
                    ${toggle_raw ? "border-white " : "border-transparent"} 
                      ${
                        bol_filtered?.toString() == "1"
                          ? `
                      text-red-200 
                        `
                          : `
                        `
                      }
                    
                    p-1 rounded-full
                    bg-gray-900 hover:bg-gray-700`}
                    >
                      <SvgJsx
                        type={`fill`}
                        icon={`code-sm`}
                        className={` w-4 h-4 flex-0 my-auto `}
                        title={"toggle"}
                      />
                    </div>
                  </div>
                </div>

                <div className="text-sm text-center mt-2 border w-full">
                  {!toggle_raw && (
                    <div
                      onClick={() => set_toggle_raw((prev) => !prev)}
                      className={`cursor-pointer break-all max-h-[200px]  text-xs p-1 mb-1 overflow-y-auto border border-gray-700 rounded-md
                                          ${
                                            bol_filtered?.toString() == "1"
                                              ? `
                                          text-red-200 
                                            `
                                              : `
                                            `
                                          }
                                          `}
                    >
                      {message}
                    </div>
                  )}

                  {toggle_raw && (
                    <div
                      onClick={() => set_toggle_raw((prev) => !prev)}
                      className="cursor-pointer menu flex flex-col rounded-md shadow-md 
                            bg-gray-800 "
                    >
                      <textarea
                        className="overflow-auto text-black text-xs "
                        defaultValue={message_original_raw}
                        rows={1}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* COL RIGHT */}
            <div className="flex-0 inline-flex flex-wrap flex-row justify-center text-center mx-auto items-start content-top  gap-1">
              {/* APPROVE */}
              <PopupMenuOption
                isDisplay={type == "message" && ssr_isadmin?.[helptype]} //&& (userdata?.id == owner_id)) }
                onClick={() => {
                  closeMenu();
                  handlers(ssr_data_tribe).approveHandler(
                    chatter_id,
                    isApproved ? "0" : "1",
                    chatter_name,
                    authContext,
                    isConfirmed
                  );
                }}
                icon="check"
                icontype="outline"
                title={isApproved ? "Un-Approve" : "Approve"}
              />

              {/* IGNORE */}
              <PopupMenuOption
                isDisplay={type == "message" && clicker_id !== chatter_id} //&& (userdata?.id == owner_id)) }
                onClick={() => {
                  closeMenu();
                  handlers(ssr_data_tribe).ignoreHandler(
                    chatter_id,
                    isIgnored ? "0" : "1",
                    chatter_name,
                    authContext,
                    isConfirmed
                  );
                }}
                icon="eye-off-sm"
                title={isIgnored ? "Un-Ignore" : "Ignore"}
              />

              {/* UNBLOCK */}
              <PopupMenuOption
                isDisplay={
                  type == "message" &&
                  ssr_isadmin?.[helptype] &&
                  clicker_id !== chatter_id &&
                  isBlocked
                }
                onClick={() => {
                  closeMenu();
                  handlers(ssr_data_tribe).blockHandler(
                    owner_id,
                    chatter_id,
                    chatter_name,
                    -1,
                    isConfirmed
                  );
                }}
                icon="scale-sm"
                title={"Unblock user"}
              />

              {ssr_isadmin?.[helptype] && (
                <>
                  <Popup
                    trigger={
                      <div aria-haspopup="true" className="menu-item ">
                        <PopupMenuOption
                          isDisplay={
                            type == "message" &&
                            ssr_isadmin?.[helptype] &&
                            clicker_id?.toString() !== chatter_id?.toString() &&
                            chatter_id?.toString() !== owner_id?.toString() &&
                            !isBlocked
                          } //&& (userdata?.id == owner_id)) }
                          icon="scale-sm"
                          title={"Moderate"}
                          onClick={() => {}}
                        />
                      </div>
                    }
                    position="left center"
                    on="click"
                    closeOnDocumentClick
                    closeOnEscape
                    mouseLeaveDelay={300}
                    mouseEnterDelay={0}
                    contentStyle={{
                      padding: "0px",
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                    arrow={false}
                    nested
                    ref={menuRef}
                  >
                    <div
                      className="menu flex flex-col gap-y-1 rounded-md shadow-md p-2
                                    bg-gray-800 "
                    >
                      {/* BLOCK */}
                      {blockactions
                        .filter(
                          (e) =>
                            ["0", "1", "2", "3", "4", "5", "6"].indexOf(
                              e.action.toString()
                            ) >= 0
                        )
                        .map((e, index) => (
                          <Fragment key={index}>
                            <PopupMenuOption
                              isDisplay={
                                type == "message" &&
                                ssr_isadmin?.[helptype] &&
                                clicker_id?.toString() !==
                                  chatter_id?.toString() &&
                                chatter_id?.toString() !==
                                  owner_id?.toString() &&
                                !isBlocked
                              }
                              onClick={() => {
                                closeMenu();
                                handlers(ssr_data_tribe).blockHandler(
                                  owner_id,
                                  chatter_id,
                                  chatter_name,
                                  e.action,
                                  isConfirmed
                                );
                              }}
                              icon="x-circle-sm"
                              title={e.button_msg}
                            />
                          </Fragment>
                        ))}
                    </div>
                  </Popup>
                </>
              )}
            </div>
          </div>
        </>
      </Popup>
    </div>
  );
};

export const ChatMessageOptions = memo(ChatMessageOptionsNested);

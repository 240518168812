import { calculateAspectRatioFit } from "@/lib/utils/calculateAspectRatioFit";
import { hasData, hasNoData } from "@/lib/utils/hasData";
import { linkify } from "@/lib/utils/linkify";
import ChatModalHeader from "./ChatModalHeader";
import Image from "next/image";
import { validJson } from "@/lib/utils/isJsonValid";
import { useEffect, useState } from "react";
import { scrollToElement } from "@/lib/utils/scrollToElement";
import { srcSafe } from "@/lib/utils/srcSafe";

export default function ChatContent({
  chat,
  profiledata,
  ssr_data_tribe,
  userdata,
  server_website,
  image,
  set_image,
  max_width,
  max_height,
  openModalChooser,
  session,
  openModal,
  closeMenu,
  closeModal,
  router,
  highlight,
  set_highlight,
}) {
  const [description, set_description] = useState(
    validJson(image?.metadata_json)?.description
  );

  const [width, set_width] = useState(validJson(image?.metadata_json)?.width);

  const [height, set_height] = useState(
    validJson(image?.metadata_json)?.height
  );

  useEffect(() => {
    set_description(validJson(image?.metadata_json)?.description);
  }, [image?.metadata_json]);
  useEffect(() => {
    set_width(validJson(image?.metadata_json)?.width);
  }, [image?.metadata_json]);
  useEffect(() => {
    set_height(validJson(image?.metadata_json)?.height);
  }, [image?.metadata_json]);

  return (
    <>
      {hasData(chat?.target_chat_id) &&
        chat?.target_chat_id?.toString() !== "0" && (
          <div
            className="text-2xs h-[16px] text-gray-500 hover:text-gray-400 hover:underline cursor-pointer"
            onClick={() => {
              set_highlight(chat?.target_chat_id);
              const element = document.getElementById(
                `msg-${chat?.target_chat_id}`
              );
              if (element) {
                setTimeout(() => {
                  scrollToElement(
                    `msg-${chat?.target_chat_id}`,
                    "smooth",
                    "center",
                    "center"
                  );
                }, 100);
              } else {
                router.push(
                  `/${profiledata?.user_name}/chat/${chat?.target_chat_id}`
                );
              }
            }}
          >
            Replying to{" "}
            <span className="font-bold">@{chat.target_user_name}</span>
            <span className="">
              {
                {
                  0: `: ${chat.target_message_preview}`,
                  1: `: ${chat.target_message_preview}`,
                  2: `'s gif`,
                  3: `'s image`,
                  4: `'s drawing`,
                  5: `'s ai art`,
                }[chat.target_message_emoji]
              }
              ...
            </span>
          </div>
        )}
      {/* TEXT */}
      {chat?.emoji?.toString() == "0" && (
        <span
          className={`text-ellipsis overflow-hidden min-w-[300px]`}
          dangerouslySetInnerHTML={{
            __html: linkify(
              userdata?.bol_ignore_filters?.toString() == "1"
                ? chat?.message_original
                : chat?.message,
              profiledata?.user_name,
              server_website
            ),
          }}
        ></span>
      )}

      {/* EMOJI */}
      {chat?.emoji?.toString() == "1" && (
        <span className={`text-ellipsis overflow-hidden text-3xl`}>
          {chat?.message_original}
        </span>
      )}

      {/* GIFY GIF */}
      {chat?.emoji?.toString() == "2" && (
        <>
          <div
            className={`break-all text-ellipsis overflow-hidden flex cursor-pointer`}
            onClick={() =>
              openModalChooser(
                "image",
                <>
                  <ChatModalHeader
                    title="Zoomed in gif"
                    chat={chat}
                    userdata={userdata}
                    session={session}
                    profiledata={profiledata}
                    ssr_data_tribe={ssr_data_tribe}
                    openModal={openModal}
                    closeMenu={closeMenu}
                    closeModal={closeModal}
                  />
                  <div className="text-center w-full flex flex-col items-center justify-center">
                    <div className="text-center w-full flex justify-center margin-auto">
                      <Image
                        src={srcSafe(image?.images?.original?.webp)}
                        alt={hasNoData(image?.title, "no alt")}
                        width={
                          calculateAspectRatioFit(
                            image?.images?.original?.width,
                            image?.images?.original?.height,
                            800,
                            600
                          ).width || 800
                        }
                        height={
                          calculateAspectRatioFit(
                            image?.images?.original?.width,
                            image?.images?.original?.height,
                            800,
                            600
                          ).height || 600
                        }
                        className="shrink-0 border bg-white max-w-full max-h-full h-full  cursor-pointer"
                        onClick={() => closeModal()}
                      />
                    </div>
                    <div className="text-center text-lg font-bold">
                      {image?.title}
                    </div>
                  </div>
                </>
              )
            }
          >
            {hasData(image?.images?.fixed_width?.url) && (
              <Image
                src={srcSafe(image?.images?.fixed_width?.url)}
                alt={hasNoData(image?.title, "no alt")}
                width={
                  calculateAspectRatioFit(
                    image?.images?.fixed_width?.width,
                    image?.images?.fixed_width?.height,
                    max_width,
                    max_height
                  ).width || max_width
                }
                height={
                  calculateAspectRatioFit(
                    image?.images?.fixed_width?.width,
                    image?.images?.fixed_width?.height,
                    max_width,
                    max_height
                  ).height || max_height
                }
                className="shrink-0 object-fit"
              />
            )}
          </div>
        </>
      )}

      {/* UPLOAD, DRAW, AI */}
      {["3", "4", "5"].indexOf(chat?.emoji?.toString()) > -1 && (
        <>
          <div
            className={`break-all text-ellipsis overflow-hidden flex cursor-pointer`}
            onClick={() =>
              openModalChooser(
                "image",
                <>
                  <ChatModalHeader
                    title="Zoomed in image"
                    chat={chat}
                    userdata={userdata}
                    session={session}
                    profiledata={profiledata}
                    ssr_data_tribe={ssr_data_tribe}
                    openModal={openModal}
                    closeMenu={closeMenu}
                    closeModal={closeModal}
                  />
                  <div className="text-center w-full flex flex-col items-center justify-center">
                    <div className="text-center w-full flex justify-center margin-auto">
                      <Image
                        src={srcSafe(image?.url)}
                        alt={hasNoData(
                          image?.description,
                          hasNoData(image?.filename, "no alt")
                        )}
                        title={description || image?.filename}
                        width={
                          calculateAspectRatioFit(
                            width || image?.width || 800,
                            height || image?.height || 800,
                            800,
                            600
                          ).width || 800
                        }
                        height={
                          calculateAspectRatioFit(
                            width || image?.width || 800,
                            height || image?.height || 800,
                            800,
                            600
                          ).height || 600
                        }
                        className="shrink-0 border bg-white max-w-full max-h-full h-full  cursor-pointer"
                        onClick={() => closeModal()}
                      />
                    </div>
                    {["3", "4", "5"].indexOf(chat?.emoji?.toString()) >= -1 &&
                      hasData(description) && (
                        <div className="text-center text-lg italic mt-2">
                          {description}
                        </div>
                      )}
                    <div className="text-center text-lg  ">
                      {image?.filename_original || image?.filename} ({width}x
                      {height})
                    </div>
                  </div>
                </>
              )
            }
          >
            {hasData(image?.url) && (
              <div className="flex flex-col">
                <Image
                  src={srcSafe(image?.url)}
                  alt={hasNoData(
                    description,
                    hasNoData(image?.filename, "no alt")
                  )}
                  title={
                    hasNoData(image?.description, image?.filename) || "no alt"
                  }
                  width={
                    calculateAspectRatioFit(
                      width || image?.width || 400,
                      height || image?.height || 400,
                      max_width,
                      max_height
                    ).width || max_width
                  }
                  height={
                    calculateAspectRatioFit(
                      width || image?.width || 400,
                      height || image?.height || 400,
                      max_width,
                      max_height
                    ).height || max_height
                  }
                  // style={{
                  //   height: calculateAspectRatioFit(
                  //     width || image?.width || 400,
                  //     height || image?.height || 400,
                  //     max_width,
                  //     max_height
                  //   ).height,
                  //   width: calculateAspectRatioFit(
                  //     width || image?.width || 400,
                  //     height || image?.height || 400,
                  //     max_width,
                  //     max_height
                  //   ).width,
                  // }}
                  className="shrink-0 bg-white"
                />
                {["3", "4", "5"].indexOf(chat?.emoji?.toString()) >= -1 &&
                  hasData(description) && (
                    <div className="text-center text-sm italic mt-2">
                      {description}
                    </div>
                  )}
              </div>
            )}
          </div>
        </>
      )}

      {/* TENOR GIFS */}
      {["12"].indexOf(chat?.emoji?.toString()) > -1 && (
        <>
          <div
            className={`break-all text-ellipsis overflow-hidden flex cursor-pointer`}
            onClick={() =>
              openModalChooser(
                "image",
                <>
                  <ChatModalHeader
                    title="Zoomed in gif"
                    chat={chat}
                    userdata={userdata}
                    session={session}
                    profiledata={profiledata}
                    ssr_data_tribe={ssr_data_tribe}
                    openModal={openModal}
                    closeMenu={closeMenu}
                    closeModal={closeModal}
                  />
                  <div className="text-center w-full flex flex-col items-center justify-center">
                    <div className="text-center w-full flex justify-center margin-auto">
                      <Image
                        src={srcSafe(image?.url)}
                        alt={hasNoData(image?.description, "no alt")}
                        width={
                          calculateAspectRatioFit(
                            image?.width,
                            image?.height,
                            800,
                            600
                          ).width || 800
                        }
                        height={
                          calculateAspectRatioFit(
                            image?.width,
                            image?.height,
                            800,
                            600
                          ).height || 600
                        }
                        className="shrink-0 border bg-white max-w-full max-h-full h-full  cursor-pointer"
                        onClick={() => closeModal()}
                      />
                    </div>
                    <div className="text-center text-lg font-bold">
                      {image.description} {image?.width}x{image?.height}
                    </div>
                  </div>
                </>
              )
            }
          >
            {hasData(image?.preview?.url) && (
              <Image
                src={srcSafe(image?.preview?.url)}
                alt={hasNoData(image?.description, "no alt")}
                width={
                  calculateAspectRatioFit(
                    image?.preview?.width,
                    image?.preview?.height,
                    max_width,
                    max_height
                  ).width || max_width
                }
                height={
                  calculateAspectRatioFit(
                    image?.preview?.width,
                    image?.preview?.height,
                    max_width,
                    max_height
                  ).height || max_height
                }
                style={{
                  height: calculateAspectRatioFit(
                    image?.preview?.width,
                    image?.preview?.height,
                    max_width,
                    max_height
                  ).height,
                  width: calculateAspectRatioFit(
                    image?.preview?.width,
                    image?.preview?.height,
                    max_width,
                    max_height
                  ).width,
                }}
                className="shrink-0 bg-white"
              />
            )}
          </div>
        </>
      )}
    </>
  );
}

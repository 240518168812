import getMessage from "@/lib/utils/getMessage";
import { useContext, useEffect, useState } from "react";
import LoadingRotary from "./loading-rotary";
import { TribeContext } from "@/components/context/tribe-context";
import { textProcess } from "@/lib/utils/textprocess";

export default function LoadingInner({
  showRandom = true,
  message = null,
  classSize = "w-32 h-32",
  classAnimate = "animate-spin-slow",
  bgColor = "#00000000",
  centerColor = "#00000000",
  pointerColor = "#c0c0c0",
  holeColor = "#00000000",
  dialerColor = "#c0c0c0aa",
  size = "64",
}) {
  const ssr_data_tribe = useContext(TribeContext);

  const [msg, set_msg] = useState(null);

  useEffect(() => {
    set_msg(showRandom ? getMessage("loading", ssr_data_tribe) : message);
  }, [showRandom, message, ssr_data_tribe?.message_loading]);

  const reloadMessage = (showRandom) => {
    //if (!hasData(message)) {
    let thismsg = showRandom ? getMessage("loading", ssr_data_tribe) : message;
    set_msg(thismsg);
    //}
  };

  return (
    <>
      <div
        className="cursor-pointer group px-10 my-10 m-auto align-center text-center text-gray-400   "
        // onClick={()=> reloadMessage(showRandom)}
      >
        <LoadingRotary
          classSize={`${classSize} mx-auto  block`}
          classAnimate={classAnimate}
          bgColor={bgColor}
          centerColor={centerColor}
          pointerColor={pointerColor}
          holeColor={holeColor}
          dialerColor={dialerColor}
          size={size}
        />
        <div className="block mt-10  group-hover:text-white text-md sm:text-lg">
          {textProcess(msg)}
        </div>
      </div>
    </>
  );
}

import { handlers } from "@/lib/utils/handlers/chat";
import ChatKudosButton from "./chat-kudos-button";
import ChatOptionButton from "./chat-option-button";
import { chatauthor } from "./chatauthor";
import ChatReportsButton from "./chat-reports-button";
import { JSONpreparrayforurl } from "@/lib/utils/JSONpreparrayforurl";
import ChatSavesButton from "./chat-saves-button";
import ChatLikesButtonAction from "./chat-likes-button-action";
import { formatPreview } from "@/lib/utils/formatPreview";

export function ChatMessageButtons({
  chat,
  profiledata,
  ssr_data_tribe,
  msg,
  set_msg,
  inputRef,
  set_commenting,
  ssr_isadmin,
  helptype,
  userdata,
  isConfirmed,
  openModal,
  message_content,
  session,
  chatoptions,
  sizeClass,
  leftSide = <></>,
}) {
  return (
    <>
      {/* ACTIONS */}
      <div className=" flex-1 flex items-center content-center ">
        <div className="flex-1">{leftSide}</div>
        <div className="flex flex-0 items-center content-center ml-1">
          {/* Reply */}
          <ChatOptionButton
            display={true}
            icon={`chat-alt-sm`}
            title={"Reply"}
            onClick={() => {
              let reply_name = chatauthor(
                chat,
                `user_name`,
                profiledata?.bot_default_name,
                profiledata?.bot_default_image
              );
              set_msg({
                //text: `@${reply_name} #${chat?.chat_id} `,
                emoji: 0,
                bol_private: chat?.bol_private == "1" ? "1" : msg.bol_private,
                target_id: chatauthor(
                  chat,
                  `id`,
                  profiledata?.bot_default_name,
                  profiledata?.bot_default_image
                ),
                target_user_name: reply_name,
                target_chat_id: chat?.chat_id,
                target_message_preview: formatPreview(
                  chat?.message_original_raw,
                  45
                ),
                target_message_emoji: chat?.emoji,
              });
              //inputRef.current.value = `@${reply_name} #${chat?.chat_id} `;
              inputRef.current.focus();
              set_commenting(true);
              console.log("fuck", {
                //text: `@${reply_name} #${chat?.chat_id} `,
                emoji: 0,
                bol_private: chat?.bol_private == "1" ? "1" : msg.bol_private,
                target_id: chatauthor(
                  chat,
                  `id`,
                  profiledata?.bot_default_name,
                  profiledata?.bot_default_image
                ),
                target_user_name: reply_name,
                target_chat_id: chat?.chat_id,
                target_message_preview: formatPreview(
                  chat?.message_original_raw,
                  45
                ),
                target_message_emoji: chat?.emoji,
              });
            }}
            className={sizeClass}
          />

          {/* KUDOS */}
          {ssr_isadmin?.[helptype] && (
            <ChatKudosButton
              bol_kudos={chat?.bol_kudos}
              onClick={() =>
                handlers(ssr_data_tribe).kudosHandler(
                  chat?.chat_id,
                  profiledata?.id
                )
              }
              className={sizeClass}
            />
          )}

          {/* REPORTS */}
          <ChatReportsButton
            reports={chat?.reports}
            reports_json={JSONpreparrayforurl(chat?.reports_json)}
            ssr_isadmin={ssr_isadmin}
            helptype={helptype}
            my_id={userdata?.id}
            onClick={() =>
              handlers(ssr_data_tribe).reportHandler(
                chat?.chat_id,
                userdata?.id,
                profiledata?.id,
                chat?.reports > 0 ? -1 : 1,
                isConfirmed
              )
            }
            openModal={openModal}
            show_numbers={false}
            chat={chat}
            className={sizeClass}
          />

          {/* SAVES */}
          <ChatSavesButton
            saves_json={JSONpreparrayforurl(chat?.saves_json)}
            my_id={userdata?.id}
            onClick={() =>
              handlers(ssr_data_tribe).saveHandler(
                chat?.chat_id,
                userdata?.id,
                profiledata?.id
              )
            }
            className={sizeClass}
          />

          {/* Delete */}
          <ChatOptionButton
            display={ssr_isadmin?.[helptype] || chat?.id == userdata?.id}
            icon={`trash-sm`}
            title={"Delete"}
            onClick={() =>
              handlers(ssr_data_tribe).removeHandler(
                chat?.chat_id,
                profiledata?.id,
                isConfirmed,
                message_content,
                chat?.id == null
                  ? profiledata?.bot_default_name
                  : chat?.user_name
              )
            }
            className={sizeClass}
          />

          {/* LIKES */}

          <div
            className={`
                                            
                                                flex-0 
    
                                                `}
          >
            <ChatLikesButtonAction
              likes_json={JSONpreparrayforurl(chat?.likes_json)}
              chatter_id={chat?.id}
              my_id={userdata?.id}
              onClick={() => {
                if (session) {
                  handlers(ssr_data_tribe).likeHandler(
                    chat?.chat_id,
                    chat?.id,
                    userdata?.id,
                    profiledata?.id
                  );
                }
              }}
              chat_emoji={profiledata?.chat_emoji}
              session={session}
              //iconSizeClass={sizeClass}
            />
          </div>
        </div>
        {chatoptions}
      </div>
    </>
  );
}

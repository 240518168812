import SvgJsx from "@/components/templateux/svg/svg-jsx";
import { hasData } from "@/lib/utils/hasData";
import { useEffect, useState } from "react";

export default function ChatReportsButton({
  reports,
  reports_json,
  ssr_isadmin,
  my_id,
  onClick,
  openModal,
  show_numbers = true,
  chat,
  helptype,
  className = `w-4 h-4`,
}) {
  // const [did_i_report,set_did_i_report] = useState(reports > 0);
  // useEffect(()=> {set_did_i_report(reports > 0)} ,[reports])

  // const [reports_json_array,set_reports_json_array] = useState(reports_json.split(','))
  // const [did_i_report,set_did_i_report] = useState(reports_json_array.indexOf(my_id) > -1)

  //     useEffect(()=>{
  //         set_reports_json_array(reports_json.split(','))
  //     },[
  //         reports_json
  //     ])

  //     useEffect(() => {

  //         set_did_i_report(reports_json_array.indexOf(my_id?.toString()) > -1)

  //   }, [
  //         reports_json_array
  //       , my_id
  //     ]);

  if (!ssr_isadmin?.[helptype]) {
    return (
      <>
        <div className="flex-0 flex items-center content-center group">
          <div
            className="flex-0 cursor-pointer rounded-md ml-1
                                 text-gray-400  hover:text-white"
            onClick={onClick}
          >
            <SvgJsx
              type={`fill`}
              icon={`flag-sm`}
              className={` ${className} flex-0 my-auto `}
              title={`report`}
            />
          </div>
        </div>
      </>
    );
  }

  if (ssr_isadmin?.[helptype]) {
    return (
      <>
        <div className="flex-0 flex items-center content-center group">
          {show_numbers && hasData(reports_json) && (
            <div
              onClick={() => {
                //openModalLikes(chat_id)
                openModal(reports_json, "users", "reports", reports, chat);
              }}
              className={`${
                reports > 0 ? "block" : "hidden"
              } ml-2 cursor-pointer opacity-0 group-hover:opacity-100 flex-0 text-xs `}
            >
              {reports}
            </div>
          )}
          <div
            className={`flex-0 cursor-pointer rounded-md ml-1 w-5
                                    ${
                                      reports > 0
                                        ? "text-red-600 hover:text-red-500"
                                        : " text-gray-400  hover:text-white"
                                    }
                                     
                                     `}
            onClick={onClick}
          >
            <SvgJsx
              type={`fill`}
              icon={`flag-sm`}
              className={` ${className} flex-0 my-auto `}
              title={reports > 0 ? `remove report` : `report`}
            />
          </div>
        </div>
      </>
    );
  }
}
